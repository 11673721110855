import React from "react";
import styled from "styled-components";
import Clock from "../components/Clock";
import { Link } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;
  text-align: center;

  @media (max-width: 700px) {
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-4rem {
    margin-top: 4rem;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }


  a {
    color: white;
  }

  .subtext {
    color: #6d6d6d;
    font-size: 0.7rem;
  }
`;

const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 3px auto;
    line-height: 1.5em;
  }
`;

const HeaderImage = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-container {
    position: relative;
    width: 75%;
    margin-top: -5%;
    margin-bottom: -10%;
  }

  .logo-container .logo-backdrop {
    --inner-margin: 20%;
    position: absolute;
    top: var(--inner-margin);
    left: var(--inner-margin);
    width: calc(100% - var(--inner-margin) * 2);
    height: calc(100% - var(--inner-margin) * 2);
    border-radius: 50%;
    z-index: -1;
    animation: pulse 6s infinite ease-in-out;
  }

  .backdrop-blue {
    --first: rgba(4, 128, 130, 255);
    --second: rgba(4, 128, 160, 0.75);
   }

   .backdrop-turquoise {
     --first: rgba(4, 130, 97, 255);
     --second: rgba(4, 128, 160, 0.75);
    }

  .backdrop-green {
    --first: rgba(4, 130, 76, 255);
    --second: rgba(4, 160, 98, 0.75);
   }

  .backdrop-gray {
    --first: rgba(128, 130, 128, 255);
    --second: rgba(128, 160, 128, 0.75);
   }

  @keyframes pulse {
    0% {
      box-shadow: 1px 5px 350px var(--first),
        1px 5px 200px var(--second);
    }
    50% {
      box-shadow: 1px 5px 200px var(--first);
    }
    100% {
      box-shadow: 1px 5px 350px var(--first),
        1px 5px 200px var(--second);
    }
  }

  .logo-container img {
    width: 100%;
    aspect-ratio: 1;
  }

  .logo-text {
    font-family: 'Noto Sans Runic', sans-serif;
    color: #d9d9d9;
    text-shadow: 1px 5px 12px #000A;
    font-size: 4em;
  }

  @media (max-width: 700px) {
    img {
      width: 80%;
    }

    .logo-text {
      font-size: 2.5em;
    }
  }

  .logo-carousel {
    --perspective: 850px;
    --spacing: min(3vw, 42px);
    --angle: 36deg;
    --rotation-speed: 20s;
    --rotation-distance: 17deg;
    display: flex;
    width: 100%;
    margin-bottom: 3em;
    z-index: -1;
    flex-direction: row;
    align-items: center;
    perspective: var(--perspective);
    transform-style: preserve-3d;
    transition: transform 2s;
    animation: tiny-rotate var(--rotation-speed) infinite ease;

    .logo-container:nth-child(1) { transform: rotateY(calc(-1 * var(--angle))) translateZ(var(--spacing)); }
    .logo-container:nth-child(2) { transform: rotateY(calc( 0 * var(--angle))) translateZ(calc(3.5*var(--spacing))); }
    .logo-container:nth-child(3) { transform: rotateY(calc( 1 * var(--angle))) translateZ(var(--spacing)); }
  }

  @keyframes tiny-rotate {
    0%   { transform: rotateY(calc(1 * var(--rotation-distance))); }
    50%  { transform: rotateY(calc(-1.1 * var(--rotation-distance))); }
    100% { transform: rotateY(calc(1 * var(--rotation-distance))); }
  }
`;

const Sponsors = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 4em;

  a > img {
    height: 90px;
    opacity: 0.75;
  }

  a:nth-child(2) {
    margin-top: 80px;
    padding: 0 50px 0 20px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    a {
      margin: 10px auto;
    }

    a:nth-child(2) {
      margin-top: 10px;
      padding: 0;
    }
  }
`;

const StyledClock = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Clock-title {
    margin-bottom: 1rem;
  }

  .Clock {
    width: 100%;
    max-width: 350px;
    // background-color: #1c1f23;
    // border: 2px solid #16181a;
    background-color: #1a1c1f;
    border: 2px solid #5e5e5e;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  .Clock .Clock-unit {
    flex: 1;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: "Red Hat Mono", monospace;
    color: #999999;
  }

  .Clock .Clock-unit .Clock-value {
    font-size: 2em;
    font-weight: 500;
  }

  .Clock .Clock-unit .Clock-label {
    font-size: 0.8em;
  }
`;

const CalenderEvents = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div a {
    padding: 0 10px;
  }

  div a img {
    vertical-align: middle;
    filter: invert(0.7) sepia(0.4);
    transition: all .2s;
  }

  div:hover a:not(:hover) img {
    opacity: 0.25;
    filter: invert(0.7) sepia(0.4) blur(1px);
  }
`;

const NiceButton = styled.a`
  padding: 1rem 2rem;
  font-size: 1.25rem;
  position: relative;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  text-align: center;
  text-decoration: none;

  &.mt-50 {
    margin-top: 50px;
  }

  &.mb-50 {
    margin-bottom: 50px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    border: 2px solid white;
    width: calc(100% + 15px);
    height: 60px;
    transition: 0.5s ease;
  }

  &::after {
    top: -15px;
    left: -15px;
    border-right: none;
    border-bottom: none;
  }

  &::before {
    bottom: -15px;
    right: -15px;
    border-left: none;
    border-top: none;
  }

  &:hover:after,
  &:hover:before {
    width: calc(100% - 30px);
    height: 80px;
  }
`;

const VariableDescription = styled.div`
  &>p {
    margin: 0;
    margin-bottom: 6px;
  }

  @media (max-width: 700px) {
    max-width: 290px;
  }
`;

export function Home() {
    const today = Date.parse(new Date());
    // =============== CONFIGURATION ==================
    const ctfOpenDate = "Mar 29 2025 12:00:00 UTC+0100";
    const ctfDayDate = "Mar 29 2025 00:00:00 UTC+0100";
    const ctfEndDate = "Mar 29 2025 20:00:00 UTC+0100"; // Duration: 8 hours
    const registrationOpenDate = "Mar 01 2025 12:00:00 UTC+0100";
    // ================================================
    const ctfIsOpen = Date.parse(ctfOpenDate) - today < 0;
    const isCtfDay = Date.parse(ctfDayDate) - today < 0;
    const ctfEnded = Date.parse(ctfEndDate) - today < 0;
    const registrationIsOpen = Date.parse(registrationOpenDate) - today < 0;
    const showTeaser = false;
    const showCountdown = false;
    const showFinalResults = true;
    const showEvent = false;
    // ================================================
    let counterTitle = "Tävlingen börjar om";
    if (isCtfDay) {
        counterTitle = "Tävlingen börjar idag om";
        document.querySelectorAll(".Clock-value").forEach(el => {
            el.style.color = "var(--color-lime)";
        });
    }
    // Format registration "22:a mars kl 12:00"
    let registrationDayText = new Date(registrationOpenDate).toLocaleDateString("sv-SE", {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
    });
    // ctfDay "29 mars 2025"
    let ctfDayText = new Date(ctfDayDate).toLocaleDateString("sv-SE", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    // Google invitation generate link
    const googleCalendarInvite = [
        "text=Undutmaning%20CTF%202025",
        "dates=20250329T110000Z/20250329T190000Z",
        "details=Undutmaning%20CTF%202025%0D%0AMer%20information%20om%20eventet%20och%20t%C3%A4vlingen%20hittas%20p%C3%A5%20https%3A%2F%2Fundutmaning.se",
        "location=", "trp=true", "sf=true", "output=xml#f"
    ].join("&");

    // ================================================
    return (
        <PageContainer>
            <PageContent>
                <Header>
                    {/* <p>
                        <date>23 mars 2024</date>
                    </p> */}
                    <h1 className="mono mono-500">HAR DU VAD SOM KRÄVS?</h1>
                    <VariableDescription className="mono">
                        <p>Prova på att lösa utmaningarna vi ställs inför varje dag. </p>
                        <p>
                            Tre myndigheter, åtta timmar, tjugofyra utmaningar inom: <br />
                            IT-forensik, kryptografi, programmering, med mera.
                        </p>
                        <p>Var med och tävla i Undutmaning.</p>
                    </VariableDescription>
                </Header>
                <HeaderImage>
                    <div className="logo-container">
                        <img alt="logo" src="/static/img/logo25.png" />
                        <div className="logo-backdrop backdrop-turquoise" />
                    </div>
                    {/* <div className="logo-carousel">
                        <div className="logo-container">
                            <img alt="logo" src="/static/img/logo22.png" style={{
                                transform: "scale(1.2) translateY(max(-32px, -3vw)) translateX(max(-16px, -1vw))"
                            }} />
                            <div className="logo-backdrop backdrop-gray" style={{
                                height: "60%",
                                marginTop: "17%",
                            }} />
                        </div>
                        <div className="logo-container">
                            <img alt="logo" src="/static/img/logo24_2.png" />
                            <div className="logo-backdrop backdrop-blue" />
                        </div>
                        <div className="logo-container">
                            <img alt="logo" src="/static/img/logo23.png" />
                            <div className="logo-backdrop backdrop-green" />
                        </div>
                    </div> */}
                    {/* UᚺDUTᛗAᚺᚾᚺG 24 */}
                    <h1 className="logo-text">&nbsp;UNDUTMANING &nbsp;CTF&nbsp;</h1>
                </HeaderImage>

                {showTeaser && (<>
                    <Header className="mt-50">
                        <h2 className="mono mono-500 mt-50">ÄVENTYRET FORTSÄTTER...</h2>
                        <p className="mono">
                            Nedräkningen till vårens CTF har börjat. <br />
                            Har du vad som krävs?<br />
                            <br />
                            Utmana dig själv och dina vänner den <b>{ctfDayText}</b> när<br />
                            Harriets utmaningar återvänder och äventyret fortsätter.
                            <br />
                            Förbered dig redan nu genom att gå med i vår <a href="/community">Discord</a>.
                        </p>
                    </Header>
                </>)}

                {(!ctfEnded && showCountdown) && (
                    <>
                        {registrationIsOpen && !ctfIsOpen && (
                            <>
                                <Header className="mt-4rem">
                                    <h2 className="mono mono-500">Registreringen har öppnat!</h2>
                                    <p className="mono">
                                        Registrera dig till CTF tävlingsplatformen. <br />
                                        Detta kan göras innan eller under tävlingen.
                                    </p>
                                </Header>
                                <NiceButton href="https://undutmaning.ctfd.io" target="_blank" className="mono mt-50">
                                    <span>Tävlingsplattformen</span>
                                </NiceButton>
                            </>
                        )}
                        <StyledClock>

                            {ctfIsOpen ? (
                                <>
                                    <h2 className="Clock-title mono mono-500" style={{
                                        color: "var(--color-lime)",
                                    }}>

                                        Tävlingen har börjat!
                                    </h2>
                                    <p className="mono">
                                        Spela nu och tävla om priser! <br />
                                        Tävlingen pågår till kl 20:00.
                                    </p>
                                    <NiceButton href="https://undutmaning.ctfd.io/" target="_blank" className="mono mt-50 mb-50">
                                        <span>Tävlingsplattformen</span>
                                    </NiceButton>
                                </>
                            ) : (
                                <h3 className="Clock-title mono">
                                    {counterTitle}
                                </h3>
                            )}
                            <Clock
                                deadline={ctfOpenDate}
                                labels={{
                                    days: "dagar",
                                    hours: "timmar",
                                    minutes: "minuter",
                                    seconds: "sekunder"
                                }}
                            />
                        </StyledClock>
                        {!registrationIsOpen && (<p className="mono subtext mt-5">
                            Registreringen till tävlingsplatformen <br />
                            öppnar den {registrationDayText}.
                        </p>)}
                        <CalenderEvents>
                            <p className="mono">Lägg till i kalendern</p>
                            <div>
                                <a
                                    href={`https://www.google.com/calendar/render?action=TEMPLATE&${googleCalendarInvite}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src="/static/img/google.svg" alt="Google" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-ctf-2025.ics"
                                    download
                                >
                                    <img src="/static/img/outlook.svg" alt="Outlook" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-ctf-2025.ics"
                                    download
                                >
                                    <img src="/static/img/apple-173.svg" alt="Apple" width="24" />
                                </a>
                                <a href="/static/undutmaning-ctf-2025.ics" download>
                                    <img
                                        src="/static/img/download-backup-svgrepo-com.svg"
                                        alt="iCal"
                                        width="24"
                                    />
                                </a>
                            </div>
                        </CalenderEvents>
                    </>
                )}

                {showFinalResults && (<>
                    <Header className="mt-50">
                        <h2 className="mono mono-500 mt-50">TÄVLINGEN ÄR ÖVER</h2>
                        <p className="mono">
                            Tack till alla som deltog i årets CTF. <br />
                            Årets lösningar kommer att publiceras inom kort.<br />
                            <b>Vi ses nästa år!</b>

                            {/* <br />
                                <br />
                                <br />
                                <NiceButton href="/results" className="mono mt-50 mb-20">
                                    <span>Resultat</span>
                                </NiceButton> */}
                        </p>
                        <h2 className="mono mono-500 mt-50">VINNARE 2025</h2>
                        <ol className="mono" style={{ fontSize: "125%" }}>
                            <li>RoyalRoppers</li>
                            <li>MSAB</li>
                            <li>KebabEngineers</li>
                        </ol>
                    </Header>
                </>)}

                <span className="mt-50" />

                {showEvent && (<Header className="mt-50">
                    <p>
                        <date>23 april 2025</date>
                    </p>
                    <h1 className="mono mono-500">VÅRT FYSISKA EVENT</h1>
                    <p className="mono">
                        Glöm inte bort att anmäla dig <br /> till det fysiska eventet! <br />
                        Läs mer och anmäl dig <a href="/event">här</a>.
                    </p>
                </Header>
                )}

                <Sponsors>
                    <Link to="/agencies#fra">
                        <img
                            src="/static/img/crest_fra.svg"
                            alt="Försvarets Radioanstalt"
                        />
                    </Link>
                    <Link to="/agencies#must">
                        <img src="/static/img/crest_fm.svg" alt="Försvarsmakten" />
                    </Link>
                    <Link to="/agencies#sapo">
                        <img src="/static/img/crest_sapo.svg" alt="Säkerhetspolisen" />
                    </Link>
                </Sponsors>
            </PageContent>
        </PageContainer>
    );
}
