import React from "react";
import styled from "styled-components";
import { Link, Route, Routes } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;
`;

const Header = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 4px auto;
  }

  a {
    color: white;
  }

  .border {
    &::after,
    &::before,
    span::after,
    span::before {
      content: "";
      position: absolute;
      background-color: white;
      transition: 0.5s ease;
    }

    &::after,
    &::before {
      height: 100%;
      width: 2px;
      top: -2px;
    }

    &::after {
      left: -2px;
    }

    &::before {
      right: -2px;
    }

    span::after,
    span::before {
      height: 2px;
      width: 100%;
      left: -2px;
    }
    span::after {
      top: -2px;
    }

    span::before {
      bottom: -2px;
    }

    &:hover:after {
      transform: translatex(-12px);
    }

    &:hover:before {
      transform: translatex(12px);
    }

    &:hover span::after {
      transform: translatey(-12px);
    }

    &:hover span::before {
      transform: translatey(12px);
    }
  }

  .swap {
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 2rem;
      box-sizing: border-box;
      opacity: 0;
      color: black;
      transition: 0.35s ease;
    }

    &::before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      width: 0;
      height: 100%;
      background-color: white;
      transition: 0.5s ease;
    }

    &:hover {
      &:before {
        width: 100%;
      }

      span {
        opacity: 1;
      }
    }
  }

  .future {
    &::after,
    &::before {
      content: "";
      position: absolute;
      border: 2px solid white;
      width: calc(100% + 15px);
      height: 60px;
      transition: 0.5s ease;
    }

    &::after {
      top: -15px;
      left: -15px;
      border-right: none;
      border-bottom: none;
    }

    &::before {
      bottom: -15px;
      right: -15px;
      border-left: none;
      border-top: none;
    }

    &:hover:after,
    &:hover:before {
      width: calc(100% - 30px);
      height: 80px;
    }
  }
`;

export function Rules() {
  return (
    <PageContainer>
      <PageContent>
        <Header>
          <h1>Spelregler</h1>

          <h2>Allmänna regler</h2>
          <p className="text-left">
            Det är strängerligen förbjudet att angripa CTF-platformen och dess
            bakomliggande system. Enbart de utmaningar som är listade i
            CTF-Portalen under "Challenges" är tillåtet att angripa. Inkluderar
            en utmaning tydliga instruktioner att angripa andra system så är det
            då, och endast då, tillåtet. Deltagare förväntas följa principen om
            "fair play" och inte försöka fuska eller sabotera för andra
            deltagare. Samarbeten och diskussioner är tillåtna, men delning av
            flaggor eller färdiga lösningar mellan olika lag/deltagare är inte
            tillåtet. (Frågor kan besvaras i mån av tid på <a href="/community">officiella Discord-kanal</a> för CTF:en)
          </p>

          <h2>Sociala regler</h2>
          <p className="text-left">
            Vi spelar på samma spelplan med samma intressen, vi är här för att
            tävla, utmanas och ha roligt. Det är därför viktigt att behandla
            andra med respekt och god ton. Tidigare event har detta skötts
            exemplariskt och vi förväntar oss samma goda, underbara ton även
            detta event, både från ledningen och deltagare!
          </p>

          <h2>Lag och deltagare</h2>
          <p className="text-left">
            Du kan delta i Undutmaning som del av ett lag eller på egen hand
            (ett väldigt litet lag). Det finns ingen begränsing i antal
            deltagare per lag (CTF:en är öppen för hur stora lag som helst), men
            eventuellt deltagandet i seminariet/fysiska eventet kommer vara
            begränsat (se nedan).
          </p>

          {/* <h2>Poäng, event och urval</h2>
          <p className="text-left">
            Poäng räknas inte med först till kvarn-regler ("first blood"), utan
            antal personer/lag som löser en utmaning avgör dess poäng i slutet
            på tävlingen. När tävlingen är över fryser vi poängen och vinnarna
            utses. Från de tre första lagen utses slumpvis max fem personer per
            lag för att tillsammans med de tre bästa solo-deltagarna få en
            garanterad plats på seminariet/fysiska eventet (om intresse har
            anmälts korrekt via formulär på <a href="/">undutmaning.se</a>).
          </p> */}
          <h2>Event och urval</h2>
          <p className="text-left">
            När tävlingen är över utses de tre bästa lagen. Från dessa lag utses
            max fem personer per lag för att få garanterad plats på fysiska
            eventet (om intresse har anmälts korrekt via formulär på{" "}
            <a href="/register">undutmaning.se/register</a>). Deltagare som inte
            är en del av ett vinnande lag kan också få plats på eventet, men slupas
            utifrån intresseanmälan.
          </p>

          <h2>Regler, klagomål och anmälan</h2>
          <p className="text-left">
            Arrangörerna förbehåller sig rätten att göra ändringar i tävlingen,
            inklusive regler och uppgifter, om det anses nödvändigt för att
            säkerställa en rättvis och säker tävling.
            <br />
            <br />
            Vid misstanke om fusk eller olämpligt beteende under eventet kan
            klagomål och anmälan av individ göras via discord till någon av
            arrangörerna. Dessa är markerade med rollen <code>"Undmin"</code>.
            {/* Efter eventet kan dessa anmälningar göras via{" "}
            <a href="mailto:hr@sakerhetspolisen.se">hr@sakerhetspolisen.se</a>. */}
            <br />
            <br />
            Arrangörerna har rätt att diskvalificera deltagare som bryter mot
            tävlingsreglerna eller beteendekoden.
            <br />
            <br />
            <hr />
            <br />
          </p>
          <p>
            Genom att delta i tävlingen godkänner deltagaren att följa dessa
            regler och respektera arrangörernas beslut. Eventuella tvister eller
            frågor om tolkning av reglerna avgörs av arrangörerna och deras
            beslut är slutgiltigt.
          </p>
        </Header>
      </PageContent>
    </PageContainer>
  );
}
