import React from "react";
import styled from "styled-components";
import { Link, Route, Routes } from "react-router-dom";
import { RegisterForm } from "../components/RegisterForm";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;

  a {
    color: #f5f5f5;
  }
`;

const Header = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 4px auto;
  }

  .border {
    &::after,
    &::before,
    span::after,
    span::before {
      content: "";
      position: absolute;
      background-color: white;
      transition: 0.5s ease;
    }

    &::after,
    &::before {
      height: 100%;
      width: 2px;
      top: -2px;
    }

    &::after {
      left: -2px;
    }

    &::before {
      right: -2px;
    }

    span::after,
    span::before {
      height: 2px;
      width: 100%;
      left: -2px;
    }
    span::after {
      top: -2px;
    }

    span::before {
      bottom: -2px;
    }

    &:hover:after {
      transform: translatex(-12px);
    }

    &:hover:before {
      transform: translatex(12px);
    }

    &:hover span::after {
      transform: translatey(-12px);
    }

    &:hover span::before {
      transform: translatey(12px);
    }
  }

  .swap {
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 2rem;
      box-sizing: border-box;
      opacity: 0;
      color: black;
      transition: 0.35s ease;
    }

    &::before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      width: 0;
      height: 100%;
      background-color: white;
      transition: 0.5s ease;
    }

    &:hover {
      &:before {
        width: 100%;
      }

      span {
        opacity: 1;
      }
    }
  }
`;

const HeaderImage = styled.div`
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;

  img {
    width: 100%;
    opacity: 0.75;
    border-radius: 12px;
  }
`;

const NiceButton = styled.a`
  padding: 1rem 2rem;
  font-size: 1.25rem;
  position: relative;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  text-align: center;
  text-decoration: none;

  &.mt-50 {
    margin-top: 50px;
  }

  &.mb-50 {
    margin-bottom: 50px;
  }

  &.mb-20 {
    margin-bottom: 20px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    border: 2px solid white;
    width: calc(100% + 15px);
    height: 60px;
    transition: 0.5s ease;
  }

  &::after {
    top: -15px;
    left: -15px;
    border-right: none;
    border-bottom: none;
  }

  &::before {
    bottom: -15px;
    right: -15px;
    border-left: none;
    border-top: none;
  }

  &:hover:after,
  &:hover:before {
    width: calc(100% - 30px);
    height: 80px;
  }
`;
const CalenderEvents = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div a {
    padding: 0 10px;
  }

  div a img {
    vertical-align: middle;
    filter: invert(0.7) sepia(0.4);
    transition: all .2s;
  }

  div:hover a:not(:hover) img {
    opacity: 0.25;
    filter: invert(0.7) sepia(0.4) blur(1px);
  }
`;

export function Event() {
    const [isRegistered, setIsRegistered] = React.useState(false);
    const registrationOpenDate = "Mars 1 2025 12:00:00 UTC+0100";
    const registrationCloseDate = "Mars 30 2025 23:00:00 UTC+0100";

    let registrationDayText = new Date(registrationOpenDate).toLocaleDateString("sv-SE", {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
    });

    let registrationCloseText = new Date(registrationCloseDate).toLocaleDateString("sv-SE", {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
    });

    const registrationHasStarted = new Date() > new Date(registrationOpenDate);
    const registrationHasEnded = new Date() > new Date(registrationCloseDate);
    return (
        <PageContainer>
            <PageContent>
                <Header>
                    <p>
                        <date>23 april 2025</date>
                    </p>
                    <h1>
                        Deltagarevent {/* på Historiska museet */}
                    </h1>
                    {/* <HeaderImage>
                        <img
                            alt="Historiska museet"
                            //   src="https://armemuseum.se/wp-content/uploads/sites/2/2023/12/Armemuseum_webbhero.jpg"
                            //   src="https://historiska.se/wp-content/uploads/2018/12/VV_spokskepp-940x562.jpg"
                            src="/static/img/historiskamuseet_webbhero.png"
                        />
                    </HeaderImage> */}

                    <p className="text-left">
                        Undutmanings fysiska event äger rum på ett museum i Stockholm,{" "}
                        {/* det historiska Historiska museet */}
                        där teknik möter tradition. Detta deltagarevent är en perfekt
                        möjlighet för nybörjare, entusiaster och professionella inom
                        cybersäkerhet att <strong>nätverka</strong> med varandra och arrangerande myndigheter.
                        Ytterligare information om eventet, såsom schema och detaljerad
                        plats kommer att skickas ut till de utvalda deltagarna.
                    </p>

                    {/* <p className="text-justify">
                        Registrera dig för att få chans att delta på det fysiska eventet på ett museum i Stockholm den 25 april 2024.
                        Detta är separat från tävlingen men <strong>kräver</strong> att du har deltagit i tävlingen för att kunna delta på eventet.
                        Observera att registrering inte garanterar plats på eventet. Vi kommer att kontakta dig om du har fått en plats.
                        <br></br>
                        Läs mer om eventet <a href="/event">här</a>.
                        <br></br>
                        <br></br>
                    </p> */}

                    {isRegistered ? (
                        <div style={{
                            color: "var(--color-lime)"
                        }}>
                            <h2>Tack för din ansökan!</h2>
                            <p>
                                Din ansökan till eventet är registrerad. Om du har några frågor, vänligen
                                kontakta administatörerna med rollen "Undmin" på Discord.
                            </p>
                        </div>
                    ) : (
                        registrationHasEnded ? (
                            <div>
                                <h2>Tack för alla registreringar!</h2>
                                <p>
                                    Registreringen till det fysiska eventet har stängt. <br />
                                    Om du har några frågor, vänligen kontakta någon med rollen "Undmin" på Discord. <br />
                                    Gå med i vår gemensamma Undutmaning Discord server <a href="/community">här</a>.
                                </p>
                            </div>
                        ) : (!registrationHasStarted ? (
                            <div>
                                <h2>Registreringen öppnar snart!</h2>
                                <p>
                                    Registreringen till det fysiska eventet öppnar den <date>{registrationDayText}</date>. <br />
                                    Om du har några frågor, vänligen kontakta någon med rollen "Undmin" på Discord. <br />
                                    Gå med i vår gemensamma Undutmaning Discord server <a href="/community">här</a>.
                                </p>
                            </div>
                        ) : (
                            <div>
                                <h2>Registrera dig idag!</h2>
                                <p>
                                    Anmäl dig idag för att tävla om en plats på eventet. <br />
                                    Vi ser fram emot att träffa er! <br />
                                    <br />
                                    <b>OBS:</b> Registreringen stänger den <date>{registrationCloseText}</date>.
                                </p>

                                <RegisterForm setIsRegistered={setIsRegistered} />
                            </div>
                        ))
                    )}


                    <CalenderEvents>
                        <p className="mono">Lägg till event i kalendern</p>
                        <div>
                            <a
                                href="https://www.google.com/calendar/render?action=TEMPLATE&text=Undutmaning%20Event%202025&dates=20250423T140000Z/20250423T180000Z&details=Undutmaning%20Event%202025%0D%0AInformation%20om%20eventet%20hittas%20p%C3%A5%20https%3A%2F%2Fundutmaning.se%2Fevent&location=To%20be%20announced&trp=true&sf=true&output=xml#f"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/static/img/google.svg" alt="Google" width="24" />
                            </a>
                            <a
                                href="webcal://undutmaning.se/static/undutmaning-event-2025.ics"
                                download
                            >
                                <img src="/static/img/outlook.svg" alt="Outlook" width="24" />
                            </a>
                            <a
                                href="webcal://undutmaning.se/static/undutmaning-event-2025.ics"
                                download
                            >
                                <img src="/static/img/apple-173.svg" alt="Apple" width="24" />
                            </a>
                            <a href="/static/undutmaning-event-2025.ics" download>
                                <img
                                    src="/static/img/download-backup-svgrepo-com.svg"
                                    alt="iCal"
                                    width="24"
                                />
                            </a>
                        </div>
                    </CalenderEvents>
                </Header>
                {/* <p>
          Läs mer om historiska museet på{" "}
          <a href="https://historiska.se/">historiska.se</a>.
        </p> */}
            </PageContent>
        </PageContainer>
    );
}
