import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em auto;
  text-align: center;

  @media (max-width: 700px) {
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-4rem {
    margin-top: 4rem;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }


  a {
    color: white;
  }

  .subtext {
    color: #6d6d6d;
    font-size: 0.7rem;
  }
`;

const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 3px auto;
    line-height: 1.5em;
  }
`;

const HeaderImage = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-container {
    position: relative;
    width: 40%;
    margin-top: -5%;
    margin-bottom: -5%;
  }

  .logo-container .logo-backdrop {
    --inner-margin: 23%;
    position: absolute;
    top: var(--inner-margin);
    left: var(--inner-margin);
    width: calc(100% - var(--inner-margin) * 2);
    height: calc(100% - var(--inner-margin) * 2);
    border-radius: 50%;
    z-index: -1;
    animation: pulse 6s infinite ease-in-out;
  }

  .backdrop-blue {
    --first: rgba(4, 128, 130, 255);
    --second: rgba(4, 128, 160, 0.75);
   }

   .backdrop-turquoise {
     --first: rgba(4, 130, 97, 255);
     --second: rgba(4, 128, 160, 0.75);
    }

  .backdrop-green {
    --first: rgba(4, 130, 76, 255);
    --second: rgba(4, 160, 98, 0.75);
   }

  .backdrop-gray {
    --first: rgba(128, 130, 128, 255);
    --second: rgba(128, 160, 128, 0.75);
   }

  @keyframes pulse {
    0% {
      box-shadow: 1px 5px 350px var(--first),
        1px 5px 200px var(--second);
    }
    50% {
      box-shadow: 1px 5px 200px var(--first);
    }
    100% {
      box-shadow: 1px 5px 350px var(--first),
        1px 5px 200px var(--second);
    }
  }

  .logo-container img {
    width: 100%;
    aspect-ratio: 1;
  }

  .logo-text {
    font-family: 'Noto Sans Runic', sans-serif;
    color: #d9d9d9;
    text-shadow: 1px 5px 12px #000A;
    font-size: 4em;
  }

  @media (max-width: 700px) {
    img {
      width: 80%;
    }

    .logo-text {
      font-size: 2.5em;
    }
  }
`;

const Sponsors = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;

  a > img {
    height: 90px;
    opacity: 0.75;
  }

  a:nth-child(2) {
    margin-top: 80px;
    padding: 0 50px 0 20px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    a {
      margin: 10px auto;
    }

    a:nth-child(2) {
      margin-top: 10px;
      padding: 0;
    }
  }
`;


const CalenderEvents = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div a {
    padding: 0 10px;
  }

  div a img {
    vertical-align: middle;
    filter: invert(0.7) sepia(0.4);
    transition: all .2s;
  }

  div:hover a:not(:hover) img {
    opacity: 0.25;
    filter: invert(0.7) sepia(0.4) blur(1px);
  }
`;

const NiceButton = styled.a`
  padding: 1rem 2rem;
  font-size: 1.25rem;
  position: relative;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  text-align: center;
  text-decoration: none;

  &.mt-50 {
    margin-top: 50px;
  }

  &.mb-50 {
    margin-bottom: 50px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    border: 2px solid white;
    width: calc(100% + 15px);
    height: 60px;
    transition: 0.5s ease;
  }

  &::after {
    top: -15px;
    left: -15px;
    border-right: none;
    border-bottom: none;
  }

  &::before {
    bottom: -15px;
    right: -15px;
    border-left: none;
    border-top: none;
  }

  &:hover:after,
  &:hover:before {
    width: calc(100% - 30px);
    height: 80px;
  }
`;

const VariableDescription = styled.div`
  &>p {
    margin: 0;
    margin-bottom: 6px;
  }

  @media (max-width: 700px) {
    max-width: 290px;
  }
`;

const TwoColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-top: 4rem;
    margin-bottom: 2rem;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

export function Invite() {
    let [params] = useSearchParams();
    let token = params.get("token");

    const TOKEN_LOADING = 1;
    const TOKEN_VALID = 2;
    const TOKEN_INVALID = 3;
    const [tokenValid, setTokenValid] = React.useState(TOKEN_LOADING);
    const [deadline, setDeadline] = React.useState(null);
    const [errorText, setErrorText] = React.useState(null);

    useEffect(() => {
        async function validateToken() {
            if (!token) return;
            try {
                const response = await fetch(`/api/invite/validate/${token}`);
                const result = await response.json();
                setTokenValid(result.isValid ? TOKEN_VALID : TOKEN_INVALID);
                let fromUNIX = new Date(result.deadline * 1000);
                let deadline = fromUNIX.toLocaleDateString("sv-SE", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                });
                setDeadline(deadline);
                if (result.isValid) {
                    localStorage.removeItem("accepted");
                    setAccepted(STATE_PENDING);
                } else {
                    setErrorText(result.reason);
                }
            } catch (error) {
                setErrorText("Det gick inte att validera din inbjudan. Försök igen senare.");
                console.error("Error validating token:", error);
                setTokenValid(TOKEN_INVALID);
            }
        }

        validateToken();
    }, [token]);

    const STATE_ACCEPTED = 1;
    const STATE_DECLINED = 2;
    const STATE_PENDING = 3;
    const localAccepted = localStorage.getItem("accepted");
    const [accepted, setAccepted] = React.useState(localAccepted === null ? STATE_PENDING : localAccepted === "true" ? STATE_ACCEPTED : STATE_DECLINED);
    const [submitting, setSubmitting] = React.useState(false); // Weather or not the form is currently submitting

    async function acceptInvite() {
        if (submitting) return; // Prevent double submission
        setSubmitting(true);
        try {
            const response = await fetch(`/api/invite/accept/${token}`, { method: "POST" });
            if (!response.ok) {
                throw new Error("Failed to accept invite due to non-200 status code.");
            }
            const result = await response.json();
            if (result.status !== "submitted") {
                throw new Error(`Failed to accept invite due to: ${result.reason}`);
            }
            setAccepted(STATE_ACCEPTED);
            localStorage.setItem("accepted", "true");
        } catch (error) {
            console.error("Error accepting invite:", error);
            setErrorText("Det gick inte att acceptera inbjudan. Försök igen senare.");
            setSubmitting(false);
            return;
        }
    }

    async function declineInvite() {
        if (submitting) return; // Prevent double submission
        setSubmitting(true);
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Är du säker på att du inte vill delta? Det går inte att ångra detta beslut.") === false) {
            setSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`/api/invite/decline/${token}`, { method: "POST" });
            if (!response.ok) {
                throw new Error("Failed to decline invite due to non-200 status code.");
            }
            const result = await response.json();
            if (result.status !== "submitted") {
                throw new Error(`Failed to decline invite due to: ${result.reason}`);
            }
            localStorage.setItem("accepted", "false");
            setAccepted(STATE_DECLINED);
        } catch (error) {
            console.error("Error declining invite:", error);
            setErrorText("Det gick inte att avböja inbjudan. Försök igen senare.");
            setSubmitting(false);
            return;
        }
    }

    return (
        <PageContainer>
            <PageContent>
                <HeaderImage style={{ marginTop: "2em" }}>
                    <div className="logo-container">
                        <img alt="logo" src="/static/img/logo25.png" />
                        <div className="logo-backdrop backdrop-turquoise" />
                    </div>
                </HeaderImage>

                {accepted === STATE_ACCEPTED ? (
                    <>
                        <Header className="mono mono-500">
                            <h1>Tack för ditt svar</h1>
                            <p>
                                Du har <span style={{
                                    color: "#0a0",
                                    fontWeight: "bold",
                                }}>accepterat</span> inbjudan.
                            </p>
                            <p style={{
                                marginTop: "1rem",
                            }}>
                                Snart får du ett mail med mer information om eventet.<br />
                                Läs mer om <Link to="/agencies">myndigheterna</Link>.<br />
                                Vi ser fram emot att träffa dig!<br />
                                Denna sida kan nu stängas.
                            </p>
                        </Header>

                        <CalenderEvents>
                            <p className="mono">Lägg till Eventet i kalendern</p>
                            <div>
                                <a
                                    href="https://www.google.com/calendar/render?action=TEMPLATE&text=Undutmaning%20Event%202025&dates=20250423T140000Z/20250423T180000Z&details=Undutmaning%20Event%202025%0D%0AInformation%20om%20eventet%20hittas%20p%C3%A5%20https%3A%2F%2Fundutmaning.se%2Fevent&location=To%20be%20announced&trp=true&sf=true&output=xml#f"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src="/static/img/google.svg" alt="Google" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-event-2025.ics"
                                    download
                                >
                                    <img src="/static/img/outlook.svg" alt="Outlook" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-event-2025.ics"
                                    download
                                >
                                    <img src="/static/img/apple-173.svg" alt="Apple" width="24" />
                                </a>
                                <a href="/static/undutmaning-event-2025.ics" download>
                                    <img
                                        src="/static/img/download-backup-svgrepo-com.svg"
                                        alt="iCal"
                                        width="24"
                                    />
                                </a>
                            </div>
                        </CalenderEvents></>
                ) : accepted === STATE_DECLINED ? (
                    <Header className="mono mono-500">
                        <h1>Tack för ditt svar</h1>
                        <p>
                            Du har <span style={{
                                color: "#a00",
                                fontWeight: "bold",
                            }}>avböjt</span> inbjudan.
                        </p>
                        <p style={{
                            marginTop: "1rem",
                        }}>
                            Denna sida kan nu stängas.
                        </p>
                    </Header>
                ) : token === null ? (
                    <Header className="mono mono-500">
                        <h1>Ogiltig inbjudan!</h1>
                        <p>
                            Saknar token.
                        </p>
                    </Header>
                ) : tokenValid === TOKEN_INVALID ? (
                    <Header className="mono mono-500">
                        <h1>Utgången inbjudan!</h1>
                        <p>
                            Du har redan svarat på inbjudan, eller så är inbjudan ogiltig.
                        </p>

                        {errorText && (
                            <p style={{
                                color: "#a00",
                            }}>{errorText}</p>
                        )}
                    </Header>
                ) : submitting ? (
                    <Header className="mono mono-500"><p>Skickar in svar...</p></Header>
                ) : tokenValid === TOKEN_LOADING ? (
                    <Header className="mono mono-500"><p>Loading...</p></Header>
                ) : tokenValid === TOKEN_VALID && accepted === STATE_PENDING && (
                    <>
                        <Header>
                            <h1 className="mono mono-500">DU ÄR INBJUDEN!</h1>
                            <h3><date>23 april 2025</date></h3>
                            <VariableDescription className="mono">
                                <p style={{
                                    fontWeight: "bold",
                                }}>
                                    Du har blivit utvald att få delta på Undutmanings fysiska event!
                                </p>
                                <p>
                                    Träffa medarbetare från tre underrättelsetjänster och andra tävlingsdeltagare.<br />
                                    Läs mer om <Link to="/agencies">myndigheterna</Link>.
                                </p>
                            </VariableDescription>
                        </Header>

                        <TwoColumn>
                            <NiceButton href="#yes" style={{
                                backgroundColor: "#1b6a1b",
                            }} onClick={acceptInvite}
                            ><b>Ja</b>, jag vill delta</NiceButton>
                            <NiceButton href="#no" style={{
                                backgroundColor: "#6a1b1b",
                            }} onClick={declineInvite}><b>Nej</b>, jag kan inte delta</NiceButton>
                        </TwoColumn>

                        {errorText && (
                            <p style={{
                                color: "#a00",
                            }}>{errorText}</p>
                        )}

                        <p>
                            {deadline ? (
                                <p>
                                    Svara senast <date>{deadline}</date>.
                                </p>
                            ) : ""}
                        </p>

                        <CalenderEvents>
                            <p className="mono">Lägg till Eventet i kalendern</p>
                            <div>
                                <a
                                    href="https://www.google.com/calendar/render?action=TEMPLATE&text=Undutmaning%20Event%202025&dates=20250423T140000Z/20250423T180000Z&details=Undutmaning%20Event%202025%0D%0AInformation%20om%20eventet%20hittas%20p%C3%A5%20https%3A%2F%2Fundutmaning.se%2Fevent&location=To%20be%20announced&trp=true&sf=true&output=xml#f"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src="/static/img/google.svg" alt="Google" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-event-2025.ics"
                                    download
                                >
                                    <img src="/static/img/outlook.svg" alt="Outlook" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-event-2025.ics"
                                    download
                                >
                                    <img src="/static/img/apple-173.svg" alt="Apple" width="24" />
                                </a>
                                <a href="/static/undutmaning-event-2025.ics" download>
                                    <img
                                        src="/static/img/download-backup-svgrepo-com.svg"
                                        alt="iCal"
                                        width="24"
                                    />
                                </a>
                            </div>
                        </CalenderEvents>
                    </>
                )}

                <Sponsors>
                    <Link to="/agencies#fra">
                        <img
                            src="/static/img/crest_fra.svg"
                            alt="Försvarets Radioanstalt"
                        />
                    </Link>
                    <Link to="/agencies#must">
                        <img src="/static/img/crest_fm.svg" alt="Försvarsmakten" />
                    </Link>
                    <Link to="/agencies#sapo">
                        <img src="/static/img/crest_sapo.svg" alt="Säkerhetspolisen" />
                    </Link>
                </Sponsors>
            </PageContent>
        </PageContainer>);
}
